import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
// import RealTalkImg from "../../images/real-talk.jpg";

const RealTalk = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHairHealthAbout2 {
        title
        text
        guide {
          file {
            url
          }
        }
        image {
          gatsbyImageData
        }
        questionAnswer {
          id
          question
          answer {
            answer
          }
        }
        downloadText
        linkLabel
        linkUrl
      }
    }
  `);
  const {
    title,
    text,
    guide,
    image,
    questionAnswer,
    downloadText,
    linkLabel,
    linkUrl,
  } = data.contentfulHairHealthAbout2;
  return (
    <div className="about_section hair-loss">
      <div className="container">
        <div className="about_inner_section">
          <div className="about_left_main_section">
            <div className="about_left_section">
              <GatsbyImage image={getImage(image)} />
              <p className="photoCaption">
                {" "}
                {downloadText} <a href={guide.file.url}> {linkLabel}</a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.06"
                  height="9.369"
                  viewBox="0 0 19.06 9.369"
                >
                  <path
                    d="M13.356,13.241H.511A.511.511,0,0,0,0,13.751v2.382a.511.511,0,0,0,.511.511H13.356V18.6a1.021,1.021,0,0,0,1.743.722l3.661-3.661a1.021,1.021,0,0,0,0-1.444L15.1,10.559a1.021,1.021,0,0,0-1.743.722Z"
                    transform="translate(0 -10.258)"
                  ></path>
                </svg>
              </p>
            </div>
          </div>
          <div className="about_right_main_section">
            <span className="real_text">{text}</span>
            <div className="section_title">
              <h2>{title}</h2>
            </div>
            {questionAnswer?.map((el) => (
              <div className="what_is_ed_section" key={el.id}>
                <h3>{el.question}</h3>
                <p>{el.answer.answer}</p>
              </div>
            ))}
            {/* <div className="what_btn">
              <Link to={linkUrl} className="common-btn">
                {linkLabel}
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealTalk;
