import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RealTalkImg from "../../images/hair-in-reach.png";

const InReach = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHairHealthInReach {
        text
        image {
          gatsbyImageData
        }
        title
        description {
          description
        }
        note
        linkLabel
        linkUrl
      }
    }
  `);
  const {
    text,
    image,
    title,
    description,
    note,
    linkLabel,
    linkUrl,
  } = data.contentfulHairHealthInReach;
  return (
    <div className="about_section hair-loss hair-in-reach">
      <div className="container">
        <div className="about_inner_section">
          <div className="about_left_main_section">
            <div className="about_left_section">
              <div className="common-section-label">
                <p>{text}</p>
              </div>
              <GatsbyImage image={getImage(image)} />
            </div>
          </div>
          <div className="about_right_main_section">
            {/* <span className="real_text">{text}</span> */}

            <div className="section_title">
              <h2>{title}</h2>
              <h3>{description.description}</h3>
              <p>{note}</p>
              <Link className="get_link " to={linkUrl}>
                <span>{linkLabel}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.06"
                  height="9.369"
                  viewBox="0 0 19.06 9.369"
                >
                  <path
                    d="M13.356,13.241H.511A.511.511,0,0,0,0,13.751v2.382a.511.511,0,0,0,.511.511H13.356V18.6a1.021,1.021,0,0,0,1.743.722l3.661-3.661a1.021,1.021,0,0,0,0-1.444L15.1,10.559a1.021,1.021,0,0,0-1.743.722Z"
                    transform="translate(0 -10.258)"
                  ></path>
                </svg>
              </Link>
            </div>

            <div className="what_btn">
              <Link to={linkUrl} className="common-btn">
                {linkLabel}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InReach;
