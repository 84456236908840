import React from "react";
import Layout from "../components/layout";
import HairBanner from "../components/hair/banner";
import RealTalk from "../components/hair/real-talk";
import InReach from "../components/hair/in-reach";
import HairPhases from "../components/hair/hair-phases";
import WhatToExpect from "../components/hair/what-to-expect";
import AvailableTreatments from "../components/hair/available_treatments";
import HowBrigoWorks from "../components/hair/how_brigo_works";
import Pricing from "../components/hair/pricing";
import MenHealth from "../components/hair/men_health";
import FAQs from "../components/hair/faq";
import GetStarted from "../components/hair/get_started";
import Reviews from "../components/hair/review";

const ProductPage = () => {
  return (
    <Layout>
      <div className="product_page">
        <div className="main-wrapper product-page hairloss-page">
          <div className="main-content">
            <HairBanner />
            <RealTalk />
            {/* Change HowBrigoWorks ComponentName Name to HairLossPhases */}
            <HairPhases />
            <AvailableTreatments />
            <WhatToExpect />
            <HowBrigoWorks />
            <InReach />
            <Pricing />
            <MenHealth />
            <Reviews />
            <FAQs />
            <GetStarted />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductPage;
