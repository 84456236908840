import React, { useEffect, useRef } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import CircleType from "circletype";

const HairPhases = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHairHealthHairLossPhases3 {
        heading
        hairLoss {
          id
          image {
            file {
              url
            }
          }
          title
          description {
            description
          }
          text1
          treatment
        }
      }
    }
  `);
  const { heading, hairLoss } = data.contentfulHairHealthHairLossPhases3;

  const circleInstanceNew = useRef();

  useEffect(() => {
    // new CircleType(circleInstanceNew.current).radius(200).dir(-1).forceWidth(450);
    // new CircleType(circleInstanceNew.current).radius(200).dir(-1).forceWidth(450); // need to initialize, as i believe it only applys the styling of the cirlce to one element by a time
    // new CircleType(circleInstanceNew.current).radius(200).dir(-1).forceWidth(450); // Need to initialize

    let curvedText = document.querySelectorAll(".circularText.product");
    if (curvedText.length) {
      console.log(curvedText);
      Array.from(curvedText).map((item) =>
        new CircleType(item).radius(200).dir(-1).forceWidth(450)
      );
    }
  }, []);

  return (
    <div className="brigo_works_section hair-phases">
      <div className="container hair-health">
        <div className="brigo_works_inner_sec">
          <div className="section_title">
            <h2>{heading}</h2>
          </div>

          <div className="brigo_all">
            <div className="brigo_row">
              {hairLoss.map((item) => (
                <div className="hairLossCol" key={item.id}>
                  <img src={item.image.file.url} />
                  <h3>{item.title}</h3>
                  <p>{item.description.description}</p>
                  <h4>
                    {item.text1} <br /> {item.treatment}
                  </h4>
                </div>
              ))}
            </div>
            <span className="mobileOnly scroll-arrow-hair-phase"> scroll <svg xmlns="http://www.w3.org/2000/svg" width="14.944" height="7.345" viewBox="0 0 14.944 7.345"><path d="M10.472,12.6H.4A.4.4,0,0,0,0,13v1.868a.4.4,0,0,0,.4.4H10.472V16.8a.8.8,0,0,0,1.367.566L14.709,14.5a.8.8,0,0,0,0-1.132l-2.871-2.871a.8.8,0,0,0-1.367.566Z" transform="translate(0 -10.258)" fill="#eb4b4b"></path></svg></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HairPhases;
