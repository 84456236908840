import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Review = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomeReviews8(page: { eq: "hair-health" }) {
        text
        heading
        reviews {
          id
          description {
            description
          }
        }
      }
    }
  `);
  const { text, heading, reviews } = data.contentfulHomeReviews8;
  return (
    <div className="review-section">
      <div className="container">
        <div className="review-block">
          <div className="common-section-label">
            <p>{text}</p>
          </div>
          <div className="common-section-light-title">
            <h2>{heading}</h2>
          </div>
          <div className="common-desc">
            <Slider {...settings}>
              {reviews?.map((review) => (
                <p key={review.id}>{review.description.description}</p>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div id="faq"></div>
    </div>
  );
};

export default Review;
