import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import HairImg from "../../images/hero-bg-2.png";

const HairBanner = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHairHealthHero1 {
        text
        heading
        description {
          description
        }
        linkLabel
        linkUrl
        image {
          gatsbyImageData
        }
      }
    }
  `);
  const {
    text,
    heading,
    description,
    linkLabel,
    linkUrl,
    image,
  } = data.contentfulHairHealthHero1;
  return (
    <div className="things_banner_section hairlossBanner">
      <div className="container">
        <div className="things_main_contain">
          <div className="product_col_6 hairloss_4_col product_right_slide_flex">
            <div className="product_right_slide">
              {/* <div className="product_right_img">
                        <img src="assets/image/product_page/product_bg-img.png" alt="product" className="product_bg_img">
                    </div> */}
              <div className="product_img"></div>
            </div>
          </div>
          <div className="things_left_contain product_col_6">
            <span>{text}</span>
            <div className="section_title">
              <h2>{heading}</h2>
            </div>
            <p className="product_dec">{description.description}</p>
            <Link to={linkUrl} className="get_link ">
              <span>{linkLabel}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.06"
                height="9.369"
                viewBox="0 0 19.06 9.369"
              >
                <path
                  d="M13.356,13.241H.511A.511.511,0,0,0,0,13.751v2.382a.511.511,0,0,0,.511.511H13.356V18.6a1.021,1.021,0,0,0,1.743.722l3.661-3.661a1.021,1.021,0,0,0,0-1.444L15.1,10.559a1.021,1.021,0,0,0-1.743.722Z"
                  transform="translate(0 -10.258)"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HairBanner;
