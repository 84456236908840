import React, { useEffect, useRef } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import CircleType from "circletype";
import BrigoDots from "../../images/brigo-dots.svg";

const HowBrigoWorks = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHairHealthWhatToExpect5 {
        heading
        image {
          file {
            url
          }
        }
        expectation {
          id
          title
          description {
            description
          }
        }
      }
    }
  `);
  const {
    heading,
    image,
    expectation,
  } = data.contentfulHairHealthWhatToExpect5;

  const circleInstanceNew = useRef();

  useEffect(() => {
    // new CircleType(circleInstanceNew.current).radius(200).dir(-1).forceWidth(450);
    // new CircleType(circleInstanceNew.current).radius(200).dir(-1).forceWidth(450); // need to initialize, as i believe it only applys the styling of the cirlce to one element by a time
    // new CircleType(circleInstanceNew.current).radius(200).dir(-1).forceWidth(450); // Need to initialize

    let curvedText = document.querySelectorAll(".circularText.product");
    if (curvedText.length) {
      console.log(curvedText);
      Array.from(curvedText).map((item) =>
        new CircleType(item).radius(200).dir(-1).forceWidth(450)
      );
    }
  }, []);

  return (
    <div className="brigo_works_section whatToExpectHair">
      <div className="container hair-health">
        <div className="brigo_works_inner_sec">
          <div className="section_title">
            <h2>{heading}</h2>
          </div>
          <img src={image.file.url} className="brigoDots" />
          <div className="brigo_row">
            {expectation.map((item) => (
              <div className="hairLossCol" key={item.id}>
                <h3>{item.title}</h3>
                <p>{item.description.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowBrigoWorks;
