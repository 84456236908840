import React, { useEffect, useRef } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import CircleType from "circletype";
import LossPreventionImg from "../../images/loss-prevention.svg"
import ThinningCrownImg from "../../images/thinning-crown.svg"
import RecedingHairlineImg from "../../images/receding-hairline.svg"
import OverallThinningImg from "../../images/overall-thinning.svg"

const HowBrigoWorks = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulProductHowBrigoWorks5(page: { eq: "hair-health" })  {
        title
        text
        brigo {
          id
          image {
            file {
              url
            }
          }
          imageText
          indexNumber
          title
          description {
            description
          }
          linkUrl
        }
        mainLabel
        mainLink
      }
    }
  `);
  const {
    title,
    text,
    brigo,
    mainLabel,
    mainLink,
  } = data.contentfulProductHowBrigoWorks5;

  const circleInstanceNew = useRef();

  useEffect(() => {
    // new CircleType(circleInstanceNew.current).radius(200).dir(-1).forceWidth(450);
    // new CircleType(circleInstanceNew.current).radius(200).dir(-1).forceWidth(450); // need to initialize, as i believe it only applys the styling of the cirlce to one element by a time
    // new CircleType(circleInstanceNew.current).radius(200).dir(-1).forceWidth(450); // Need to initialize
    
    let curvedText = document.querySelectorAll(".circularText.product");
    if (curvedText.length) {
      console.log(curvedText);
      Array.from(curvedText).map((item) =>
        new CircleType(item).radius(200).dir(-1).forceWidth(450)
      );
    }
  }, []);

  return (
    <div className="brigo_works_section">
      <div className="container">
        <div className="brigo_works_inner_sec">
          <p className="btigo_dec">{text}</p>
          <div className="section_title">
            <h2>{title}</h2>
          </div>

          <div className="brigo_all">
            <div className="brigo_row">
              {brigo?.map((el) => (
                <div className="brigo_col" key={el.id}>
                  <div className="brigo_contain">
                    <div className="brigo_img">
                      <img src={el.image.file.url} alt="bringo" />
                    </div>

                    <div className="half_circle_word half_circle_word_first">
                      <div
                        ref={circleInstanceNew}
                        className="circularText product"
                      >
                        {el.imageText}
                      </div>
                    </div>

                    <div className="start_details">
                      <span className="start_number">{el.indexNumber}</span>
                      <h3>
                        <Link to={el.linkUrl}>{el.title}</Link>
                      </h3>
                      <p>{el.description.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="brigo_btn">
            <Link to={mainLink} className="common-btn download_btn">
              {mainLabel}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.06"
                height="9.369"
                viewBox="0 0 19.06 9.369"
              >
                <path
                  d="M13.356,13.241H.511A.511.511,0,0,0,0,13.751v2.382a.511.511,0,0,0,.511.511H13.356V18.6a1.021,1.021,0,0,0,1.743.722l3.661-3.661a1.021,1.021,0,0,0,0-1.444L15.1,10.559a1.021,1.021,0,0,0-1.743.722Z"
                  transform="translate(0 -10.258)"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowBrigoWorks;
